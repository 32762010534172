import React from 'react';
import { graphql } from 'gatsby';
import {
  ItemsListIndexPage,
  PageWithSubNav,
  Paragraph,
  Section,
} from 'docComponents';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav pageType="design" title="Content Elements" subnav="words">

      <Section>
        <ItemsListIndexPage
          data={data}
          tierTwo="content-elements"
          listType="word"
          listLayout="2"
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query voicetoneIndexQuery($tierOne: String = "Words") {
    ...indexList
  }
`;
